import React from "react"
import tw from "twin.macro"

// Components
import Accordion from "@components/accordion"
import Anchor from "@components/Anchor"
import Container from "@components/container"
import Layout from "@components/layout"
import PrimaryCta from "@components/PrimaryCta"
import Seo from "@components/seo"
import { heading1, Heading2 } from "@styles/ui"

// Assets
import xywavColourfulLogoImg from "@images/logos/xywav-logo.svg"

const faqs = [
  {
    heading: <>What is XYWAV?</>,
    content: (
      <>
        <p>
          XYWAV is the first and only lower-sodium oxybate approved by the FDA
          to treat cataplexy or excessive daytime sleepiness in people 7 years
          and older with narcolepsy.
        </p>
        <p>
          A clinical trial demonstrated the efficacy and safety of XYWAV for the
          treatment of cataplexy and excessive daytime sleepiness in adult
          patients with narcolepsy.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <strong>
            <Anchor link="/narcolepsy/what-is-xywav/" hasArrow>
              Learn more about XYWAV
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: (
      <>
        How is XYWAV different from XYREM<sup>®</sup> (sodium oxybate) oral
        solution?
      </>
    ),
    content: (
      <>
        <p>
          XYWAV has the same amount of oxybate as sodium oxybate, with 92% less
          sodium in each nightly dose.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <strong>
            {" "}
            <Anchor
              link="/narcolepsy/lower-sodium-oxybate-treatment-option/"
              hasArrow
            >
              Learn more about XYWAV
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>Does XYWAV taste the same as XYREM?</>,
    content: (
      <>
        <p>
          Because XYWAV contains calcium, magnesium, and potassium, it will
          taste different than XYREM.
        </p>
      </>
    ),
  },
  {
    heading: <>Is there an artificial sweetener in XYWAV? If so, which one?</>,
    content: (
      <>
        <p>XYWAV contains sucralose.</p>
      </>
    ),
  },
  {
    heading: <>Can I change from XYREM treatment&nbsp;to XYWAV?</>,
    content: (
      <>
        <p>
          Your doctor can help determine whether XYWAV is right for you. Talk to
          your doctor about an appropriate treatment plan.
        </p>{" "}
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <strong>
            <Anchor link="/narcolepsy/transitioning-from-xyrem/" hasArrow>
              Learn more about transitioning to XYWAV
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>Why did Jazz Pharmaceuticals make&nbsp;XYWAV?</>,
    content: (
      <>
        <p>
          XYREM contains ~1640&nbsp;mg of sodium per day at the maximum
          recommended nightly dose for adults. XYWAV, with 131&nbsp;mg of sodium
          per maximum recommended total nightly dose for adults, was developed
          to provide a lower-sodium oxybate option.
        </p>{" "}
        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <strong>
            <Anchor link="/narcolepsy/what-is-xywav/" hasArrow>
              Learn more about XYWAV
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>How do I take XYWAV?</>,
    content: (
      <>
        <p>
          If your doctor prescribes you XYWAV, he or she will give you detailed
          instructions on how to take your nightly doses. You will also receive
          additional materials to ensure that you understand how to mix and take
          your nightly XYWAV doses.{" "}
          <Anchor link="/narcolepsy/dosing/">Learn more</Anchor>
          &nbsp;about dosing.
        </p>

        <span tw="block text-[15px] mt-[30px] xl:(mt-0 text-xl)">
          <strong>
            <Anchor
              link="https://pp.jazzpharma.com/pi/xywav.en.MG.pdf"
              hasArrow
              newTab
            >
              Please refer to the Medication Guide for detailed information
              about preparing and taking XYWAV
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>What are the possible side effects of&nbsp;XYWAV?</>,
    content: (
      <>
        {" "}
        <p>
          The most common side effects of XYWAV in adults include headache,
          nausea, dizziness, decreased appetite, parasomnia (a sleep disorder
          that can include abnormal dreams, abnormal rapid eye movement (REM)
          sleep, sleep paralysis, sleep talking, sleep terror, sleep-related
          eating disorder, sleepwalking, and other abnormal sleep-related
          events), diarrhea, excessive sweating (hyperhidrosis), anxiety, and
          vomiting. In patients who remained on treatment, side effects tended
          to occur early and diminish over time.
        </p>
        <p>
          In children, the most common side effects include nausea, bedwetting,
          vomiting, headache, weight decrease, decreased appetite, dizziness,
          and sleepwalking.
        </p>
        <p>
          These are not all the possible side effects of XYWAV. For more
          information, ask your doctor or pharmacist. Call your doctor for
          medical advice about side effects.
        </p>
        <span tw="block text-[15px] mt-[30px] xl:(mt-0 text-xl)">
          <strong>
            <Anchor
              link="https://pp.jazzpharma.com/pi/xywav.en.USPI.pdf"
              newTab
              hasArrow
            >
              Please see the full Prescribing Information, including BOXED
              Warning, and Medication Guide
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>How much does XYWAV cost?</>,
    content: (
      <>
        <p>
          Jazz Pharmaceuticals is committed to making sure that people who are
          prescribed XYWAV can get it affordably.
        </p>

        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <strong>
            <Anchor link="/narcolepsy/jazzcares-for-xywav/" hasArrow>
              Learn about financial assistance options for qualified patients
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>What support is available if my doctor prescribes XYWAV?</>,
    content: (
      <>
        <p>
          Jazz Pharmaceuticals, the leader in sleep medicine, is committed to
          helping you get therapy support and assistance throughout your
          treatment journey based on your personal needs every step of the way.
        </p>
        <ul tw="ml-2.5">
          <li>
            <Anchor bold link="/narcolepsy/patient-support-programs/">
              Nurse and pharmacy support
            </Anchor>
            —Your JazzCares<sup>&reg;</sup> Nurse Case Manager will be with you
            from the very beginning in your treatment journey, and a pharmacist
            is available 24/7 to answer questions about your prescription
          </li>
          <li>
            <Anchor bold link="/narcolepsy/patient-support-programs/">
              Access and affordability
            </Anchor>
            —The Certified Pharmacy will assist you with navigating the
            insurance process and understanding your insurance coverage to help
            get your treatment started
          </li>
          <li>
            <Anchor bold link="/narcolepsy/patient-resources/">
              Personalized resources
            </Anchor>
            —At different points along the way, you will receive relevant tools
            and resources to help as you start and continue your therapy,
            including:
            <ul tw="pl-10">
              <li>
                <Anchor isExternal newTab link="https://www.mywav.com">
                  myWAV
                </Anchor>
                <sup>&trade;</sup>, the 24/7 digital tool for personalized
                support and motivation
              </li>
              <li>
                A Starter Kit to help you get started on your path to your first
                shipment of XYWAV
              </li>
              <li>
                A Welcome Kit with everything you need to manage your XYWAV
                treatment
              </li>
              <li>Prescription refill reminders</li>
              <li>
                Tools and resources to help you consider your overall health
                when managing your narcolepsy
              </li>
              <li>
                Information and support resources for caregivers and loved ones
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: <>Why can&apos;t I get XYWAV at my local pharmacy?</>,
    content: (
      <>
        <p>
          Because of the risk of central nervous system (CNS) depression, abuse,
          and misuse, XYWAV is available only through a restricted distribution
          program called the XYWAV and XYREM REMS. You or your child must be
          enrolled in this program to receive XYWAV.
        </p>
        <p>
          In addition, the Certified Pharmacy is the only pharmacy in the United
          States that is permitted to fill your XYWAV prescription and send it
          directly to you.
        </p>

        <span tw="block text-[15px] mt-[30px] xl:mt-0">
          <strong>
            <Anchor link="/narcolepsy/getting-xywav/" hasArrow>
              Learn more
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
]

const FaqPage = ({ location }) => {
  return (
    <Layout location={location} mainBgColour="seashell">
      <Seo
        title="FAQ for Treatment of 2 Narcolepsy Symptoms | XYWAV®"
        description="Explore commonly asked questions about XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution for 2 symptoms of narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <section tw="bg-seashell relative pt-8 xl:(py-14)">
        {/* <img
          tw="block absolute h-auto z-0 w-[139px] top-[35px] right-[-19px] xl:( w-[631px] top-[19px] right-[-146px])"
          src={xywavColourfulLogoImg}
          alt=""
        /> */}

        <Container>
          <img
            tw="block absolute h-auto z-0 w-[139px] top-0 right-[-19px] md:(w-[400px]) xl:( w-[631px] top-4 right-[-300px])"
            src={xywavColourfulLogoImg}
            alt=""
          />
          <span tw="block uppercase mb-2.5 font-extrabold tracking-wider pt-7 text-xs md:pt-12 xl:(text-2xl pt-16)">
            Why XYWAV
          </span>
          <h1 css={[heading1, tw`text-merlin`]}>FAQ</h1>
        </Container>
      </section>

      <section tw="bg-seashell">
        <div tw="mt-[1.875rem] mb-[3.125rem] w-full xl:(mt-0 mb-20)">
          <div tw="rounded-lg w-full bg-white grid relative z-10 max-w-3xl m-auto py-[3.125rem] px-[4.8%] xl:(py-20 px-[6.125rem] max-w-6xl)">
            {faqs.map((item, i) => {
              return (
                <Accordion
                  key={i}
                  heading={item.heading}
                  content={item.content}
                />
              )
            })}
          </div>
        </div>
      </section>

      {/* Doctor discussion guide */}
      <section css={[tw`gradient12 py-8 px-4 text-center lg:(py-[50px])`]}>
        <h2
          css={[
            Heading2,
            tw`text-white mb-7 lg:(max-w-[1000px] mx-auto)`,
            tw`before:(block mx-auto mb-7 h-1.5 rounded-md gradientRed w-[100px] lg:w-[124px])`,
          ]}
        >
          Stay Connected
        </h2>
        <p tw="text-white px-5 mb-6">
          Sign up to receive important information about XYWAV treatment and
          narcolepsy.
        </p>
        <PrimaryCta mdSmall url="/narcolepsy/stay-connected/">
          Sign Up
        </PrimaryCta>
      </section>
    </Layout>
  )
}

export default FaqPage
